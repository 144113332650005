var generic = generic || {};

var SiteOverlayManager = (function(SiteOverlayManager, Drupal, generic, $) {
  // Load once
  if (!!SiteOverlayManager.loaded) {
    return SiteOverlayManager;
  }

  SiteOverlayManager.loaded = true;
  SiteOverlayManager.opened = false;
  SiteOverlayManager.started = false;
  SiteOverlayManager.pending = {};

  SiteOverlayManager.requestOverlay = function(args, meta) {
    var launch_id = meta.id;
    if (!launch_id) {
      console.warn('We require a launch id');
      return;
    }

    var overlayPack = {args: args, meta: meta};
    this.pending[launch_id] = overlayPack;
  };

  /*
   * Start the overlay manager loop. Instead of a time interval, possible to
   * wait for colorbox events. Internal for now since colorbox lifecycle events
   * are a bit wonky.
   */
  SiteOverlayManager.start = function() {
    if (this.started) {
      return;
    }

    window.setInterval(function() {
      SiteOverlayManager.loopOnce();
    }, 500);

    this.started = true;
  };

  SiteOverlayManager.loopOnce = function() {
    if (this.isActive()) {
      // already have open cbox
      return;
    }

    var overlayPack = this.findNext();
    if (!overlayPack) {
      return;
    }

    var overlayArgs = overlayPack.args;
    var meta = overlayPack.meta;
    var launch_id = meta.id;
    generic.overlay.launch(overlayArgs);
    delete this.pending[launch_id];
  };

  /*
   * Safest way to detect whether a colorbox is active since colorbox doesn't
   * expose its active state.
   */
  SiteOverlayManager.isActive = function() {
    var $cbox = $('#colorbox');
    if (!$cbox.length) {
      return false;
    }
    return $cbox.is(':visible');
  };

  /*
   * Find next overlayPack based on its weight. Highest weight wins.
   */
  SiteOverlayManager.findNext = function() {
    var winningPack = null;
    var weight = null;
    for (var k in this.pending) {
      var overlayPack = this.pending[k];
      var meta = overlayPack.meta;
      var overlayWeight = meta.weight || 0;

      if (weight === null || overlayWeight > weight) {
        winningPack = overlayPack;
        weight = overlayWeight;
      }
    }
    if (winningPack !== null) {
      return winningPack;
    }
  };

  /*
   * Notes on cbox lifecycle events. open, cleanup, closed do *not* get called
   * when you call $.colorbox() a second time while the first colorbox is still
   * active.
   *
   * This is super annoying. At some point, we might want to use the cbox_load
   * event to detect when cololboxes that don't play nicely are opened. For
   * now, SiteOverlayManager is largely for handling on-load type of
   * colorboxes.
   */
  $(document).off('.overlayManager');

  $(document).on('cbox_open.overlayManager', function() {
    SiteOverlayManager.opened = true;
  });

  $(document).on('cbox_load.overlayManager', function() {
  });

  $(document).on('cbox_closed.overlayManager', function() {
    SiteOverlayManager.opened = false;
  });

  Drupal.behaviors.siteoverlaymanager = {
    attach: function() {
      // this makes sure we run after all behaviors have the chance to attach.
      // This technically won't work for any behaviors that add an onload modal
      // *after* making some sort of async call.
      SiteOverlayManager.start();
    },
  };

  return SiteOverlayManager;
})(SiteOverlayManager || {}, Drupal, generic, jQuery);
